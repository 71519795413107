@import '@angular/cdk/overlay-prebuilt.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .decoration-skip-ink-none {
    text-decoration-skip-ink: none;
  }
}

body {
  background: #fafafa;
  color: rgba(black, 0.87);
  --accent-surface: rgb(242 242 244);
  --disabled: rgba(0, 0, 0, 0.38);
  --disabled-surface: rgba(0, 0, 0, 0.12);
  --error: rgb(244 67 54);
  --error-surface: rgb(254 226 226);
  --foreground: 33 33 33;
  --success: rgb(20 123 58);
  --success-surface: rgb(220 252 231);
  --surface: #fff;
  --warning: rgb(107, 89, 0); // #6b5900
  --warning-surface: rgb(255, 213, 0); // #ffd500
  &.dark-mode {
    background: #303030;
    color: white;
    --accent-surface: #555555;
    --disabled: rgba(255, 255, 255, 0.5);
    --disabled-surface: #4e4e4e;
    --error: rgb(254 226 226);
    --error-surface: rgb(244 67 54);
    --foreground: 255 255 255;
    --success: rgb(220 252 231);
    --success-surface: rgb(21 128 61);
    --surface: #424242;
    --warning: rgb(255, 213, 0); // #ffd500
    --warning-surface: rgb(107, 89, 0); // #6b5900
  }
}
